import React, {
    useEffect,
    useState
} from 'react';
import axiosInstance from '../../components/AxiosInstance';
import {
    Link,
    Outlet,
    useNavigate,
    useParams
} from 'react-router-dom';
import {
    config
} from '../../config/config.js';
import {
    useAuth
} from "../../components/AuthProvider";
import Menu from "../../components/Menu";
import TopBar from "../../components/TopBar";
import delete_svg from '../../assets/img/delete.svg';
import small_loader_svg from '../../assets/img/small_loader.svg';

export default function EstimatePage() {
    const {
        id
    } = useParams()
    const navigate = useNavigate();
    const [tableRow, setTableRow] = useState(0);
    const [estimate, setEstimate] = useState([]);

    const [files, setFiles] = useState([]);
    const [isLoading, setisLoading] = useState(true);
    const [isError, setisError] = useState([]);
    const [isSuccess, setisSuccess] = useState([]);

    // console.log((new Date()));
    /*
      useEffect(() => {
        setisLoading(true);
    // console.log('token', axios.defaults.headers.common["Authorization"])
       axiosInstance.get("/base/estimates/")
        .then(function(response){

          setEstimates(response.data)
          setisLoading(false)
        })
        .catch((err) => {
            setisError(true);
            setisLoading(false);
          });
      }, [])*/

    const handleAddFiles = (e) => {


        document.querySelector('.others .upload_files input').click()
    }
    const handleAddFilesNecessary = (e) => {


        document.querySelector('.necessary .upload_files input').click()
    }
    const handleDownload = (e) => {
        let file_id = e.target.parentNode.getAttribute("file_id")

        axiosInstance.get(`/base/report/file/${file_id}/`, {
                responseType: 'arraybuffer'
            }).then(function(response) {

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', response.headers['content-disposition'].split('filename=')[1]);
                document.body.appendChild(link);
                link.click();


            })
            .catch((err) => {

            });

    }

    const handleDelete = (e) => {
        let file_id = parseInt(e.target.parentNode.parentNode.getAttribute("file_id"))

        axiosInstance.delete(`/base/report/file/${file_id}/`, {
                responseType: 'arraybuffer'
            }).then(function(response) {

                setFiles(files.filter(item => item.id !== file_id))
            })
            .catch((err) => {
                alert(err)
            });

    }
    const handleFilesChangeNecessary = (e) => {


        var formData = new FormData();
        // var imagefile = document.querySelector('#file');

        let files_array = []
        for (var i = 0; i < e.target.files.length; i++) {

            formData.append("files", e.target.files[i]);

            files.push({
                'loading': true,
                'name': e.target.files[i].name,
                'necessary': true
            })
        }

        formData.append("necessary", true);


        setFiles([...files])
        document.querySelector('.necessary .upload_files input').value = "";

        axiosInstance.post('/base/report/file/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(function(response) {

                let newFiles = files.filter(item => !item.loading)
                newFiles = newFiles.concat(response.data.files)
                setFiles(newFiles)

                // setEstimate([...estimate, {}])
                // console.log(response.data)

                setisLoading(false)
            })
            .catch((err) => {

                setisLoading(false);
            });


    }

    const handleFilesChange = (e) => {


        var formData = new FormData();
        // var imagefile = document.querySelector('#file');

        let files_array = []
        for (var i = 0; i < e.target.files.length; i++) {

            formData.append("files", e.target.files[i]);

            files.push({
                'loading': true,
                'name': e.target.files[i].name
            })
        }



        setFiles([...files])
        document.querySelector('.others .upload_files input').value = "";

        axiosInstance.post('/base/report/file/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(function(response) {

                let newFiles = files.filter(item => !item.loading)
                newFiles = newFiles.concat(response.data.files)
                setFiles(newFiles)

                // setEstimate([...estimate, {}])
                // console.log(response.data)

                setisLoading(false)
            })
            .catch((err) => {

                setisLoading(false);
            });


    }
    const handleInputCheck = (e) => {
        if (e.key != 'Tab' && e.key != 'Enter') {


            //let value=parseFloat(e.target.value.replace(",", '.').replace(/[^.\d\-]/g,''))

            let value = parseFloat(e.target.value.replace(",", '.'))
            /*
        let nullvalue=false
        if(value==-0) nullvalue=true
      
        let firstminus=false
        if(e.target.value.slice(0,1)=='-')
          firstminus=true

        

        let lastdot=false
        if(e.target.value.replace(",", '.').slice(-1)=='.')
          lastdot=true

        if(value!=0 && !value) value=''
        if(lastdot) {

          e.target.value=value.toString().replace(".", ',')+','
        }
        else {
          if(firstminus && value=='' && !nullvalue) 
          
          e.target.value=value.toString().replace(".", ',')
        }*/
            /*
                  if(firstminus && value=='' && !nullvalue) 
                   e.target.value='-'
                  else if(firstminus && nullvalue && !lastdot) 
                   e.target.value='-0'*/

            if (!value) value = 0
            let max = parseFloat(e.target.parentNode.parentNode.nextSibling.innerText.replace(" ", '').replace(/\u00a0/g, '').replace(",", '.'))

            if (value < 0 || value <= max) {
                e.target.parentNode.classList.remove("error");
                //перерасчёт итогов

                let work_id = e.target.parentNode.parentNode.parentNode.getAttribute("work_id")

                estimate.works.forEach((work) => {
                    if (work.work_estimate == work_id) {

                        Object.keys(estimate.total).forEach((currency, i) => {
                            if (work.money[currency]) {



                                if (work.money[currency]['cost_materials_per_unit']) {
                                    document.querySelectorAll("#table tr[work_id='" + work_id + "'] td")[9 + 5 * i].innerText = Intl.NumberFormat("ru", {
                                        minimumFractionDigits: 2
                                    }).format((work.money[currency]['cost_materials_per_unit'] * value).toFixed(2))
                                    document.querySelectorAll("#table tr[work_id='" + work_id + "'] td")[9 + 5 * (Object.keys(estimate.total).length + i)].innerText = Intl.NumberFormat("ru", {
                                        minimumFractionDigits: 2
                                    }).format((work.money[currency]['cost_materials_per_unit'] * (work.workload - work.workload_report_progress - value)).toFixed(2))
                                }
                                if (work.money[currency]['cost_installation_per_unit']) {
                                    document.querySelectorAll("#table tr[work_id='" + work_id + "'] td")[11 + 5 * i].innerText = Intl.NumberFormat("ru", {
                                        minimumFractionDigits: 2
                                    }).format((work.money[currency]['cost_installation_per_unit'] * value).toFixed(2))
                                    document.querySelectorAll("#table tr[work_id='" + work_id + "'] td")[11 + 5 * (Object.keys(estimate.total).length + i)].innerText = Intl.NumberFormat("ru", {
                                        minimumFractionDigits: 2
                                    }).format((work.money[currency]['cost_installation_per_unit'] * (work.workload - work.workload_report_progress - value)).toFixed(2))
                                }
                                if (work.money[currency]['cost_materials_per_unit'] || work.money[currency]['cost_installation_per_unit']) {
                                    document.querySelectorAll("#table tr[work_id='" + work_id + "'] td")[12 + 5 * i].innerText = Intl.NumberFormat("ru", {
                                        minimumFractionDigits: 2
                                    }).format(((work.money[currency]['cost_materials_per_unit'] + work.money[currency]['cost_installation_per_unit']) * value).toFixed(2))
                                    document.querySelectorAll("#table tr[work_id='" + work_id + "'] td")[12 + 5 * (Object.keys(estimate.total).length + i)].innerText = Intl.NumberFormat("ru", {
                                        minimumFractionDigits: 2
                                    }).format(((work.money[currency]['cost_materials_per_unit'] + work.money[currency]['cost_installation_per_unit']) * (work.workload - work.workload_report_progress - value)).toFixed(2))

                                }

                            }
                        })

                    }

                })

            } else {
                e.target.parentNode.classList.add("error");
            }
            //пересчёт итогов
            Object.keys(estimate.total).forEach((currency, i) => {
                let new_total = 0
                document.querySelectorAll("#table tr[work_id] td:nth-child(" + (13 + 5 * i) + ")").forEach((total, i) => {
                    if (total.innerText)
                        new_total = new_total + parseFloat(total.innerText.replace(/\s+/g, '').replace(",", '.'))
                    // console.log(total.innerText.replace(/\s+/g, '').replace(",", '.'), i)
                })

                let new_remainder = 0
                document.querySelectorAll("#table tr[work_id] td:nth-child(" + (13 + 5 * (Object.keys(estimate.total).length + i)) + ")").forEach((total, i) => {
                    if (total.innerText)
                        new_remainder = new_remainder + parseFloat(total.innerText.replace(/\s+/g, '').replace(",", '.'))
                })
                
                if (estimate.tax) {
                    if (estimate.tax_value != 'Без НДС') {
                        document.querySelector("#total td:nth-child(" + (6 + 5 * i) + ") b").innerText = Intl.NumberFormat("ru", {
                            minimumFractionDigits: 2
                        }).format((new_total * (1 - parseInt(estimate.tax_value) / 100)).toFixed(2))
                        document.querySelector("#total_tax td:nth-child(" + (6 + 5 * i) + ") b").innerText = Intl.NumberFormat("ru", {
                            minimumFractionDigits: 2
                        }).format((new_total * parseInt(estimate.tax_value) / 100).toFixed(2))
                        document.querySelector("#total_full td:nth-child(" + (6 + 5 * i) + ") b").innerText = Intl.NumberFormat("ru", {
                            minimumFractionDigits: 2
                        }).format(new_total.toFixed(2))

                        document.querySelector("#total td:nth-child(" + (6 + 5 * (Object.keys(estimate.total).length+i)) + ") b").innerText = Intl.NumberFormat("ru", {
                            minimumFractionDigits: 2
                        }).format((new_remainder * (1 - parseInt(estimate.tax_value) / 100)).toFixed(2))
                        document.querySelector("#total_tax td:nth-child(" + (6 + 5 * (Object.keys(estimate.total).length+i)) + ") b").innerText = Intl.NumberFormat("ru", {
                            minimumFractionDigits: 2
                        }).format((new_remainder * parseInt(estimate.tax_value) / 100).toFixed(2))
                        document.querySelector("#total_full td:nth-child(" + (6 + 5 * (Object.keys(estimate.total).length+i)) + ") b").innerText = Intl.NumberFormat("ru", {
                            minimumFractionDigits: 2
                        }).format(new_remainder.toFixed(2))

                    } else {
                        document.querySelector("#total td:nth-child(" + (6 + 5 * i) + ") b").innerText = Intl.NumberFormat("ru", {
                            minimumFractionDigits: 2
                        }).format(new_total.toFixed(2))
                        document.querySelector("#total td:nth-child(" + (6 + 5 * (Object.keys(estimate.total).length+i)) + ") b").innerText = Intl.NumberFormat("ru", {
                            minimumFractionDigits: 2
                        }).format(new_remainder.toFixed(2))
                    }
                } else {
                    document.querySelector("#total td:nth-child(" + (6 + 5 * i) + ") b").innerText = Intl.NumberFormat("ru", {
                        minimumFractionDigits: 2
                    }).format(new_total.toFixed(2))

                    document.querySelector("#total td:nth-child(" + (6 + 5 * (Object.keys(estimate.total).length+i)) + ") b").innerText = Intl.NumberFormat("ru", {
                        minimumFractionDigits: 2
                    }).format(new_remainder.toFixed(2))


                    if (estimate.tax_value != 'Без НДС') {
                        document.querySelector("#total_tax td:nth-child(" + (6 + 5 * i) + ") b").innerText = Intl.NumberFormat("ru", {
                            minimumFractionDigits: 2
                        }).format((new_total * parseInt(estimate.tax_value) / 100).toFixed(2))
                        document.querySelector("#total_full td:nth-child(" + (6 + 5 * i) + ") b").innerText = Intl.NumberFormat("ru", {
                            minimumFractionDigits: 2
                        }).format((new_total * (parseInt(estimate.tax_value) + 100) / 100).toFixed(2))

                        document.querySelector("#total_tax td:nth-child(" + (6 + 5 * (Object.keys(estimate.total).length+i)) + ") b").innerText = Intl.NumberFormat("ru", {
                            minimumFractionDigits: 2
                        }).format((new_remainder * parseInt(estimate.tax_value) / 100).toFixed(2))
                        document.querySelector("#total_full td:nth-child(" + (6 + 5 * (Object.keys(estimate.total).length+i)) + ") b").innerText = Intl.NumberFormat("ru", {
                            minimumFractionDigits: 2
                        }).format((new_remainder * (parseInt(estimate.tax_value) + 100) / 100).toFixed(2))
                    }
                }

            })
            /*
            let value=parseFloat(e.target.value.replace(",", '.').replace(/[^.\d]/g,''))
            let lastdot=false
            if(e.target.value.replace(",", '.').slice(-1)=='.')
              lastdot=true

            if(!value) value=''
            if(lastdot)
              e.target.value=value.toString().replace(".", ',')+','
            else
              e.target.value=value.toString().replace(".", ',')

            let max=parseFloat(e.target.parentNode.parentNode.nextSibling.innerText.replace(",", '.').replace(/[^.\d]/g,''))

            if(value<=max) {
              e.target.parentNode.classList.remove("error");

            } else {
              e.target.parentNode.classList.add("error");
            }*/
        }
    }
    const handleInput = (e) => {


        if (e.key == 'Tab' || e.key == 'Enter') {
            let value = parseFloat(e.target.value.replace(",", '.'))
            let max = parseFloat(e.target.parentNode.parentNode.nextSibling.innerText.replace(" ", '').replace(/\u00a0/g, '').replace(",", '.'))

            if (value > 0 && value > max) {
                e.target.parentNode.classList.add("error");

            } else if (value != 0) {


                e.target.parentNode.classList.remove("error");
                e.target.value = value.toString().replace(".", ',')


            } else {
                e.target.value = ''
            }
        }

        // e.target.previousSibling.disabled = true;
        // e.target.style.display = 'none';
        // e.target.nextSibling.style.display = 'inline-block';
        // e.target.previousSibling.parentNode.classList.add("saved");
    }

    /*

       const handleEdit = (e) => {
          e.target.previousSibling.previousSibling.disabled = false;
          e.target.previousSibling.previousSibling.focus();


          let estimate=parseInt(e.target.parentNode.parentNode.parentNode.parentNode.parentNode.getAttribute("estimate_id"))
          let work=parseInt(e.target.parentNode.parentNode.parentNode.getAttribute("work_id"))
          axiosInstance.post('/base/report/', {'estimate':estimate, 'work':work, 'workload':0}).then(function (response) {
              if(response.status === 200){
                console.log('remove report')
              }
          })
          .catch(function (error) {
            console.log(error)
          });

       }*/

    const handleSend = (e) => {


        let data = []
        let error_text = []
        let error_input = false
        let date = document.getElementById('date').value
        let date_from = document.getElementById('date_from').value
        let date_to = document.getElementById('date_to').value

        let inputs = document.querySelectorAll("#table input");

        inputs.forEach((input) => {
            if (!error_input) {
                //let value=parseFloat(input.value.replace(",", '.').replace(/[^.\d]/g,''))
                let value = parseFloat(input.value.replace(",", '.'))
                if (value) {
                    let work = parseInt(input.parentNode.parentNode.parentNode.getAttribute('work_id'))
                    let max = parseFloat(input.parentNode.parentNode.nextSibling.innerText.replace(" ", '').replace(/\u00a0/g, '').replace(",", '.'))

                    if (value > 0 && value > max) error_input = true
                    console.log(id, error_input, work, value, max)
                    data.push({
                        'work': work,
                        'workload': value
                    })
                }
            }



            // console.log(input.parentNode.parentNode.nextSibling.innerText)

        });


        if (data.length == 0) {
            error_text.push('Укажите объём выполненных работ')
        }

        if (error_input) {
            error_text.push('Неверный объём работ')
        }

        if (!date) {
            error_text.push('Неверная дата')
        }

        if (!date_from) {
            error_text.push('Неверная начальная дата')
        }
        if (!date_to) {
            error_text.push('Неверная конечная дата')
        }


        if (date_from && date_to) {
            if (new Date(date_from) > new Date(date_to)) {
                error_text.push('Дата начала не может быть больше даты конца')
            }
        }

        if (!files.some(item => item.necessary)) {
            error_text.push('Прикрепите подтверждающую документацию')
        }

        if (error_text) {
            setisError(error_text)
        }

        if (error_text.length == 0) {
            setisLoading(true);
            setisSuccess([])
            axiosInstance.post('/base/report/', {
                    'estimate': parseInt(id),
                    'date': date,
                    'date_from': date_from,
                    'date_to': date_to,
                    'data': data
                }).then(function(response) {
                    if (response.status === 200) {

                        document.getElementById('date').value = ''
                        document.getElementById('date_from').value = ''
                        document.getElementById('date_to').value = ''
                        inputs.forEach((input) => {
                            input.value = ''
                        })

                        setisSuccess(['Добавлено'])

                        // alert('Отчёт сформирован, перезагрузка страницы')
                        // axiosInstance.get(`/base/estimate/${id}`)
                        // navigate(0)
                        // setisLoading(false);
                        axiosInstance.get(`/base/estimate/${id}`)
                            .then(function(response) {


                                setEstimate(response.data)
                                setFiles(response.data.files)
                                setTableRow(2 + Object.keys(response.data['total']).length * 5)
                                setisLoading(false)
                            })
                            .catch((err) => {
                                setisError(true);
                                setisLoading(false);
                            });

                    }
                })
                .catch(function(error) {
                    alert(error)
                    setisLoading(false);
                });


        }


        // alert('отправка')

    }

    useEffect(() => {
        setisLoading(true);


        axiosInstance.get(`/base/estimate/${id}`)
            .then(function(response) {


                setEstimate(response.data)
                // console.log(response.data.files)
                setFiles(response.data.files)
                setTableRow(2 + Object.keys(response.data['total']).length * 5)
                setisLoading(false)

                /*
                Object.keys(estimate.total).forEach((currency, i) => {
                  let new_total_reports = 0
                  document.querySelectorAll("#table tr[work_id] td:nth-child(" + (13 + 5 * (Object.keys(estimate.total).length + i)) + ")").forEach((total, i) => {
                      if (total.innerText)
                          new_total_reports = new_total_reports + parseFloat(total.innerText.replace(/\s+/g, '').replace(",", '.'))
                      // console.log(total.innerText.replace(/\s+/g, '').replace(",", '.'), i)
                  })
                   console.log('132', new_total_reports)
                })
*/

            })
            .catch((err) => {
                setisError(true);
                setisLoading(false);
            });


    }, [])

    useEffect(() => {
      if(estimate.total) {
        for (let j = 1; j < 5; j++) {
          Object.keys(estimate.total).forEach((currency, i) => {
                let new_total = 0
                  

                  
                  document.querySelectorAll("#table tr[work_id] td:nth-child(" + (13+5*i+5*Object.keys(estimate.total).length*j) + ")").forEach((total, i) => {
           
                      if (total.innerText)
                          new_total = new_total + parseFloat(total.innerText.replace(/\s+/g, '').replace(",", '.'))
                      // console.log(total.innerText.replace(/\s+/g, '').replace(",", '.'), i)
                  })


                  if (estimate.tax) {
                    if (estimate.tax_value != 'Без НДС') {
                       
                        document.querySelector("#total td:nth-child(" + (6+5*i+5*Object.keys(estimate.total).length*j) + ") b").innerText = Intl.NumberFormat("ru", {
                            minimumFractionDigits: 2
                        }).format((new_total * (1 - parseInt(estimate.tax_value) / 100)).toFixed(2))
                        document.querySelector("#total_tax td:nth-child(" + (6+5*i+5*Object.keys(estimate.total).length*j) + ") b").innerText = Intl.NumberFormat("ru", {
                            minimumFractionDigits: 2
                        }).format((new_total * parseInt(estimate.tax_value) / 100).toFixed(2))
                        document.querySelector("#total_full td:nth-child(" + (6+5*i+5*Object.keys(estimate.total).length*j) + ") b").innerText = Intl.NumberFormat("ru", {
                            minimumFractionDigits: 2
                        }).format(new_total.toFixed(2))

                    } else {

                        document.querySelector("#total td:nth-child(" + (6+5*i+5*Object.keys(estimate.total).length*j) + ") b").innerText = Intl.NumberFormat("ru", {
                            minimumFractionDigits: 2
                        }).format(new_total.toFixed(2))
                    }
                } else {


                    document.querySelector("#total td:nth-child(" + (6+5*i+5*Object.keys(estimate.total).length*j) + ") b").innerText = Intl.NumberFormat("ru", {
                        minimumFractionDigits: 2
                    }).format(new_total.toFixed(2))


                    if (estimate.tax_value != 'Без НДС') {

                        document.querySelector("#total_tax td:nth-child(" + (6+5*i+5*Object.keys(estimate.total).length*j) + ") b").innerText = Intl.NumberFormat("ru", {
                            minimumFractionDigits: 2
                        }).format((new_total * parseInt(estimate.tax_value) / 100).toFixed(2))
                        document.querySelector("#total_full td:nth-child(" + (6+5*i+5*Object.keys(estimate.total).length*j) + ") b").innerText = Intl.NumberFormat("ru", {
                            minimumFractionDigits: 2
                        }).format((new_total * (parseInt(estimate.tax_value) + 100) / 100).toFixed(2))
                    }
                }
                })
        }
      }
      
    })
  return (  
    <>
    <Menu/>
    <div id="page">
    <TopBar/>
    <div className="container">
      
      {estimate.number?
        <>
          <div className="header">Подача работ по накопительной {estimate.number} от {new Date(estimate.date).toLocaleDateString()}</div>

          <div className="card_row">
            <div className="card">
                <div className="card_header">{estimate.number}</div>
                <div className="card_date">от {new Date(estimate.date).toLocaleDateString()}</div>
            </div>
            <div className="card pt">
              <div className="card_table">
                <div className="card_attr">Договор</div>
                <div className="card_value"><Link to={`/deal/${estimate.deal_id}/`}>{estimate.deal_name}</Link></div>
              </div>
              <div className="card_table">
                <div className="card_attr">Проект</div>
                <div className="card_value"><Link to={`/project/${estimate.project_id}/`}>{estimate.project_name}</Link></div>
              </div>
              <div className="card_table">
                <div className="card_attr">Объект</div>
                <div className="card_value"><Link to={`/object/${estimate.object_id}/`}>{estimate.object_name}</Link></div>
              </div>
               <div className="card_table">
                <div className="card_attr">Налоговая ставка</div>
                <div className="card_value">{estimate.tax_value}</div>
              </div>
            
            </div>
            <div className="card">
                
                
            </div>
          </div>

          <div className="block">
           <div className="block_table">

            <table className="list" id="table" estimate_id={estimate.id}>
            <thead>
              <tr>
                 
                <th rowSpan={2}>Наименование работ</th>
                <th rowSpan={2}>ID</th>
               
                {estimate.relevance?<th rowSpan={2}>Внести</th>:null}
                <th rowSpan={2}>Остаток</th>
                <th rowSpan={2}>На утверждении</th>
                <th rowSpan={2}>Кол-во, закрытое КС-2 (накопление)</th>
                <th rowSpan={2}>Кол-во</th>
                <th rowSpan={2}>Ед.изм.</th>
                <th colSpan={5*Object.keys(estimate.total).length}>Подача</th>
                <th colSpan={5*Object.keys(estimate.total).length}>Остаток</th>
                <th colSpan={5*Object.keys(estimate.total).length}>На утверждении</th>
                <th colSpan={5*Object.keys(estimate.total).length}>Кол-во, закрытое КС-2 (накопление)</th>
                <th colSpan={5*Object.keys(estimate.total).length}>В сметах</th>
                
                
                
              </tr>
              <tr>

              {estimate.total && Object.keys(estimate.total).map((currency) => (  
                    <>
                    <th>Цена материала за ед., {currency!=='null'?currency:'Валюта'}{!estimate.tax?', без НДС':null}</th>
                    <th>Стоимость материала, {currency!=='null'?currency:'Валюта'}{!estimate.tax?', без НДС':null}</th>
                    <th>Цена работ за ед., {currency!=='null'?currency:'Валюта'}{!estimate.tax?', без НДС':null}</th>
                    <th>Стоимость работ, {currency!=='null'?currency:'Валюта'}{!estimate.tax?', без НДС':null}</th>
                    <th>Стоимость Итого, {currency!=='null'?currency:'Валюта'}{!estimate.tax?', без НДС':null}</th>
                    </>
                  ))}
              {estimate.total && Object.keys(estimate.total).map((currency) => (  
                    <>
                    <th>Цена материала за ед., {currency!=='null'?currency:'Валюта'}{!estimate.tax?', без НДС':null}</th>
                    <th>Стоимость материала, {currency!=='null'?currency:'Валюта'}{!estimate.tax?', без НДС':null}</th>
                    <th>Цена работ за ед., {currency!=='null'?currency:'Валюта'}{!estimate.tax?', без НДС':null}</th>
                    <th>Стоимость работ, {currency!=='null'?currency:'Валюта'}{!estimate.tax?', без НДС':null}</th>
                    <th>Стоимость Итого, {currency!=='null'?currency:'Валюта'}{!estimate.tax?', без НДС':null}</th>
                    </>
                  ))}
              {estimate.total && Object.keys(estimate.total).map((currency) => (  
                    <>
                    <th>Цена материала за ед., {currency!=='null'?currency:'Валюта'}{!estimate.tax?', без НДС':null}</th>
                    <th>Стоимость материала, {currency!=='null'?currency:'Валюта'}{!estimate.tax?', без НДС':null}</th>
                    <th>Цена работ за ед., {currency!=='null'?currency:'Валюта'}{!estimate.tax?', без НДС':null}</th>
                    <th>Стоимость работ, {currency!=='null'?currency:'Валюта'}{!estimate.tax?', без НДС':null}</th>
                    <th>Стоимость Итого, {currency!=='null'?currency:'Валюта'}{!estimate.tax?', без НДС':null}</th>
                    </>
                  ))}
              {estimate.total && Object.keys(estimate.total).map((currency) => (  
                    <>
                    <th>Цена материала за ед., {currency!=='null'?currency:'Валюта'}{!estimate.tax?', без НДС':null}</th>
                    <th>Стоимость материала, {currency!=='null'?currency:'Валюта'}{!estimate.tax?', без НДС':null}</th>
                    <th>Цена работ за ед., {currency!=='null'?currency:'Валюта'}{!estimate.tax?', без НДС':null}</th>
                    <th>Стоимость работ, {currency!=='null'?currency:'Валюта'}{!estimate.tax?', без НДС':null}</th>
                    <th>Стоимость Итого, {currency!=='null'?currency:'Валюта'}{!estimate.tax?', без НДС':null}</th>
                    </>
                  ))}
              {estimate.total && Object.keys(estimate.total).map((currency) => (  
                    <>
                    <th>Цена материала за ед., {currency!=='null'?currency:'Валюта'}{!estimate.tax?', без НДС':null}</th>
                    <th>Стоимость материала, {currency!=='null'?currency:'Валюта'}{!estimate.tax?', без НДС':null}</th>
                    <th>Цена работ за ед., {currency!=='null'?currency:'Валюта'}{!estimate.tax?', без НДС':null}</th>
                    <th>Стоимость работ, {currency!=='null'?currency:'Валюта'}{!estimate.tax?', без НДС':null}</th>
                    <th>Стоимость Итого, {currency!=='null'?currency:'Валюта'}{!estimate.tax?', без НДС':null}</th>
                    </>
                  ))}
              </tr>
               </thead>       
               <tbody>

                {estimate.works && estimate.works.map((work) => (  
                  <>
                    
                     {work.section ?
              <tr className="section show" level={work.level} key={work.number}>
                <td>{work.name}</td>
                <td>{work.number}</td>
                <td></td>
               {estimate.relevance?<td></td>:null}
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                {Object.keys(estimate.total).map((currency) => ( 
                <>
                <td className="right"></td>
                <td className="right"></td>
                <td className="right"></td>
                <td className="right"></td>
                <td className="right"></td>
                </>
              ))}
                {Object.keys(estimate.total).map((currency) => ( 
                <>
                <td className="right"></td>
                <td className="right"></td>
                <td className="right"></td>
                <td className="right"></td>
                <td className="right"></td>
                </>
              ))}
                {Object.keys(estimate.total).map((currency) => ( 
                <>
                <td className="right"></td>
                <td className="right"></td>
                <td className="right"></td>
                <td className="right"></td>
                <td className="right"></td>
                </>
              ))}
                {Object.keys(estimate.total).map((currency) => ( 
                <>
                <td className="right"></td>
                <td className="right"></td>
                <td className="right"></td>
                <td className="right"></td>
                <td className="right"></td>
                </>
              ))}
                {Object.keys(estimate.total).map((currency) => ( 
                <>
                <td className="right"></td>
                <td className="right"></td>
                <td className="right"></td>
                <td className="right"></td>
                <td className="right"></td>
                </>
              ))}
                


               
                
          
              </tr>
             :
              <tr className="show"  work_id={work.work_estimate} level={work.level} key={work.number}>
                <td>{work.name}</td>
                <td><span>{work.number}</span></td>
                
                {estimate.relevance?
                <td className="center">
                {(work.workload_report_progress!=work.workload)?
                  <div className="report">
                    <input type="text" onKeyDown={handleInput} onKeyUp={handleInputCheck} />
                    <div className="text">больше остатка</div>
                  </div>
                  :<div className="done">Выполнено</div>
                }</td>
                :null}
                <td className="center">{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format((work.workload-work.workload_report_progress).toFixed(2))}</td>
                <td className="center report_save">{work.workload_report?<>{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(work.workload_report)}</>:null}</td>
                <td className="center">{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(work.workload_progress)}</td>
                <td className="center">{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(work.workload)}</td>
                <td className="center">{work.unit}</td>
                {Object.keys(estimate.total).map((currency) => ( 
            <>
            
              {work.money[currency] ?
                <>  
                  {work.money[currency].cost_materials_per_unit ?
                   <>
                    <td className="right">{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(work.money[currency].cost_materials_per_unit)}</td>
                    <td className="right">0,00</td>
                   </>
                  : 
                   <><td className="right"></td>
                    <td className="right"></td></>}

                   

                   {work.money[currency].cost_installation_per_unit ?
                   <><td className="right">{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(work.money[currency].cost_installation_per_unit)}</td>
                   <td className="right">0,00</td></>
                  : 
                   <><td className="right"></td>
                 <td className="right"></td></>}

                   

                 {(work.money[currency].cost_materials_per_unit || work.money[currency].cost_installation_per_unit)?
                  <td className="right">0,00</td>:
                  <td className="right"></td>
                }
                  
                </>
              :
                <><td className="right"></td>
                  <td className="right"></td>
                  <td className="right"></td>
                  <td className="right"></td>
                  <td className="right"></td></>
              
              
              
              }
                

                    
                  </>
                ))}
                  {Object.keys(estimate.total).map((currency) => ( 
            <>
            
              {work.money[currency] ?
                <>  
                  {work.money[currency].cost_materials_per_unit ?
                   <>
                    <td className="right">{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(work.money[currency].cost_materials_per_unit)}</td>
                    <td className="right">{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format((work.money[currency].cost_materials_per_unit*(work.workload-work.workload_report_progress)).toFixed(2))}</td>
                   </>
                  : 
                   <><td className="right"></td>
                    <td className="right"></td></>}

                   

                   {work.money[currency].cost_installation_per_unit ?
                   <><td className="right">{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(work.money[currency].cost_installation_per_unit)}</td>
                   <td className="right">{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format((work.money[currency].cost_installation_per_unit*(work.workload-work.workload_report_progress)).toFixed(2))}</td></>
                  : 
                   <><td className="right"></td>
                 <td className="right"></td></>}

                   

                 {(work.money[currency].cost_materials_per_unit || work.money[currency].cost_installation_per_unit)?
                  <td className="right">{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(((work.money[currency].cost_installation_per_unit+work.money[currency].cost_materials_per_unit)*(work.workload-work.workload_report_progress)).toFixed(2))}</td>:
                  <td className="right"></td>
                }
                  
                </>
              :
                <><td className="right"></td>
                  <td className="right"></td>
                  <td className="right"></td>
                  <td className="right"></td>
                  <td className="right"></td></>
              
              
              
              }
                

                    
                  </>
                ))}
                  {Object.keys(estimate.total).map((currency) => ( 
            <>
            
              {work.money[currency] ?
                <>  
                  {work.money[currency].cost_materials_per_unit ?
                   <>
                    <td className="right">{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(work.money[currency].cost_materials_per_unit)}</td>
                    <td className="right">{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format((work.money[currency].cost_materials_per_unit*work.workload_report).toFixed(2))}</td>
                   </>
                  : 
                   <><td className="right"></td>
                    <td className="right"></td></>}

                   

                   {work.money[currency].cost_installation_per_unit ?
                   <><td className="right">{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(work.money[currency].cost_installation_per_unit)}</td>
                   <td className="right">{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format((work.money[currency].cost_installation_per_unit*work.workload_report).toFixed(2))}</td></>
                  : 
                   <><td className="right"></td>
                 <td className="right"></td></>}

                   

                 {(work.money[currency].cost_materials_per_unit || work.money[currency].cost_installation_per_unit)?
                  <td className="right">{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(((work.money[currency].cost_installation_per_unit+work.money[currency].cost_materials_per_unit)*work.workload_report).toFixed(2))}</td>:
                  <td className="right"></td>
                }
                  
                </>
              :
                <><td className="right"></td>
                  <td className="right"></td>
                  <td className="right"></td>
                  <td className="right"></td>
                  <td className="right"></td></>
              
              
              
              }
                

                    
                  </>
                ))}
                  
                  {Object.keys(estimate.total).map((currency) => ( 
            <>
            
              {work.money[currency] ?
                <>  
                  {work.money[currency].cost_materials_per_unit ?
                   <>
                    <td className="right">{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(work.money[currency].cost_materials_per_unit)}</td>
                    <td className="right">{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format((work.money[currency].cost_materials_per_unit*work.workload_progress).toFixed(2))}</td>
                   </>
                  : 
                   <><td className="right"></td>
                    <td className="right"></td></>}

                   

                   {work.money[currency].cost_installation_per_unit ?
                   <><td className="right">{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(work.money[currency].cost_installation_per_unit)}</td>
                   <td className="right">{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format((work.money[currency].cost_installation_per_unit*work.workload_progress).toFixed(2))}</td></>
                  : 
                   <><td className="right"></td>
                 <td className="right"></td></>}

                   

                 {(work.money[currency].cost_materials_per_unit || work.money[currency].cost_installation_per_unit)?
                  <td className="right">{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(((work.money[currency].cost_installation_per_unit+work.money[currency].cost_materials_per_unit)*work.workload_progress).toFixed(2))}</td>:
                  <td className="right"></td>
                }
                  
                </>
              :
                <><td className="right"></td>
                  <td className="right"></td>
                  <td className="right"></td>
                  <td className="right"></td>
                  <td className="right"></td></>
              
              
              
              }
                

                    
                  </>
                ))}
                  {Object.keys(estimate.total).map((currency) => ( 
            <>
            
              {work.money[currency] ?
                <>  
                  {work.money[currency].cost_materials_per_unit ?
                   <>
                    <td className="right">{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(work.money[currency].cost_materials_per_unit)}</td>
                    <td className="right">{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format((work.money[currency].cost_materials_per_unit*work.workload).toFixed(2))}</td>
                   </>
                  : 
                   <><td className="right"></td>
                    <td className="right"></td></>}

                   

                   {work.money[currency].cost_installation_per_unit ?
                   <><td className="right">{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(work.money[currency].cost_installation_per_unit)}</td>
                   <td className="right">{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format((work.money[currency].cost_installation_per_unit*work.workload).toFixed(2))}</td></>
                  : 
                   <><td className="right"></td>
                 <td className="right"></td></>}

                   

                 {(work.money[currency].cost_materials_per_unit || work.money[currency].cost_installation_per_unit)?
                  <td className="right">{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(((work.money[currency].cost_installation_per_unit+work.money[currency].cost_materials_per_unit)*work.workload).toFixed(2))}</td>:
                  <td className="right"></td>
                }
                  
                </>
              :
                <><td className="right"></td>
                  <td className="right"></td>
                  <td className="right"></td>
                  <td className="right"></td>
                  <td className="right"></td></>
              
              
              
              }
                

                    
                  </>
                ))}
              </tr>
              }

              
              </>
              ))}

                {estimate.indirects && estimate.indirects.length?
                <>
                <tr id="indirect" className="section show" level="1">
                    <td><b>Косвенные затраты</b></td>
                    <td>Уровень</td>
                    <td colSpan={estimate.relevance?6:5}>Способ начисления</td>
                    {Object.keys(estimate.money).map((currency) => ( 
                        <td colSpan="5"></td>
                    ))}
                    {Object.keys(estimate.money).map((currency) => ( 
                        <td colSpan="5"></td>
                    ))}
                    {Object.keys(estimate.money).map((currency) => ( 
                        <td colSpan="5"></td>
                    ))}
                    {Object.keys(estimate.money).map((currency) => ( 
                        <td colSpan="5"></td>
                    ))}
                    {Object.keys(estimate.money).map((currency) => ( 
                        <><td className="right" colSpan="4">Процент, {currency}</td>
                        <td className="right">Сумма, {currency}</td></>
                    ))}
                  

                </tr>
                {Object.keys(estimate.indirects).map((indirect) => ( 
                        <tr id="indirect" className="show" >
                            <td>{estimate.indirects[indirect].name}</td>
                            <td>{estimate.indirects[indirect].level}</td>
                            <td colSpan={estimate.relevance?6:5}>{estimate.indirects[indirect].accrual}</td>
                            {Object.keys(estimate.money).map((currency) => ( 
                                <td colSpan="5"></td>
                            ))}
                            {Object.keys(estimate.money).map((currency) => ( 
                                <td colSpan="5"></td>
                            ))}
                            {Object.keys(estimate.money).map((currency) => ( 
                                <td colSpan="5"></td>
                            ))}
                            {Object.keys(estimate.money).map((currency) => ( 
                                <td colSpan="5"></td>
                            ))}
                            {Object.keys(estimate.money).map((currency) => ( 

                                <>
                                {estimate.indirects[indirect].moneys[currency]?
                                <><td className="right" colSpan="4">{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(estimate.indirects[indirect].moneys[currency].percent.toFixed(2))}</td>
                                <td className="right">{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(estimate.indirects[indirect].moneys[currency].cost_total.toFixed(2))}</td>
                                </>:<><td colSpan="4"></td><td></td></>
                            }
                            </>
                            ))}
                            
                        </tr>
                    ))}
                </>
                :null}
                <tr id="total" className="show" >
                  <td className="right" colSpan={estimate.relevance?8:7}><b>Всего по смете,  без НДС:</b></td>
                  
                  {Object.keys(estimate.money).map((currency) => ( 
                    <>              
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="right"><b>0,00</b></td>
                    </>
                  ))}
                  {Object.keys(estimate.money).map((currency) => ( 
                    <>              
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="right"><b>0,00</b></td>
                    </>
                  ))}
                  {Object.keys(estimate.money).map((currency) => ( 
                    <>              
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="right"><b>0,00</b></td>
                    </>
                  ))}
                  {Object.keys(estimate.money).map((currency) => ( 
                    <>              
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="right"><b>0,00</b></td>
                    </>
                  ))}
                  {Object.keys(estimate.money).map((currency) => ( 
                    <>              
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="right"><b>0,00</b></td>
                    </>
                  ))}
                </tr>
                {estimate.tax_value!='Без НДС'?
                <tr id="total_tax" className="show" >
                  <td className="right" colSpan={estimate.relevance?8:7}><b>НДС {estimate.tax_value}:</b></td>
                  
                  {Object.keys(estimate.money).map((currency) => ( 
                    <>              
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="right"><b>0,00</b></td>
                    </>
                  ))}
                  {Object.keys(estimate.money).map((currency) => ( 
                    <>              
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="right"><b>0,00</b></td>
                    </>
                  ))}
                  {Object.keys(estimate.money).map((currency) => ( 
                    <>              
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="right"><b>0,00</b></td>
                    </>
                  ))}
                  {Object.keys(estimate.money).map((currency) => ( 
                    <>              
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="right"><b>0,00</b></td>
                    </>
                  ))}
                  {Object.keys(estimate.money).map((currency) => ( 
                    <>              
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="right"><b>0,00</b></td>
                    </>
                  ))}

                </tr>
                :null}
                {estimate.tax_value!='Без НДС'?
                <tr id="total_full" className="show" >
                  <td className="right" colSpan={estimate.relevance?8:7}><b>Всего по смете,  с НДС {estimate.tax_value}:</b></td>
                  
                  {Object.keys(estimate.money).map((currency) => ( 
                    <>              
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="right"><b>0,00</b></td>
                    </>
                  ))}
                  {Object.keys(estimate.money).map((currency) => ( 
                    <>              
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="right"><b>0,00</b></td>
                    </>
                  ))}
                  {Object.keys(estimate.money).map((currency) => ( 
                    <>              
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="right"><b>0,00</b></td>
                    </>
                  ))}
                  {Object.keys(estimate.money).map((currency) => ( 
                    <>              
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="right"><b>0,00</b></td>
                    </>
                  ))}
                  {Object.keys(estimate.money).map((currency) => ( 
                    <>              
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="right"><b>0,00</b></td>
                    </>
                  ))}
                </tr>
                :null}
                </tbody>
             
            </table>


          </div>

          {estimate.relevance?
          <div className="block_report">
            <div className="setting_info">Дата отчёта</div>
            <div className="setting_value">
              <input id="date" type="date" min="2000-01-01" defaultValue={new Date().toISOString().split('T')[0]} />
            </div>
            <div className="setting_info">Период отчёта</div>
            <div className="setting_value">
              <input id="date_from" type="date" min="2000-01-01" max={new Date().toISOString().split('T')[0]} /><div className="separator">-</div>
              <input id="date_to" type="date" min="2000-01-01" max={new Date().toISOString().split('T')[0]} defaultValue={new Date().toISOString().split('T')[0]}/>
            </div>

            <div className="files necessary">
                <div className="header">Подтверждающая документация</div>
                {files && files.map((file)=>(
                   <>{file.necessary?
                  <>{file.error?<div className="error" key={file.id}>Ошибка загрузки файла {file.name}</div>:null}
                    {file.loading?<div className="loading" key={file.id}><img src={small_loader_svg}/>Загружается {file.name}</div>:null}
                    {!file.loading&&!file.error?<div file_id={file.id} key={file.id}><div className="delete" onClick={handleDelete}><img src={delete_svg}/></div><Link onClick={handleDownload}>{file.name}</Link></div>:null}
                    
                  
                  </>:null}</>

                 
                  ))}
                <div className="upload_files">
              <div className="button" onClick={handleAddFilesNecessary}>Добавить файлы</div>
              <input
                type="file"
                multiple={true}
                
                accept=".jpg,.jpeg,.png,.pdf,.doc,.xls,.xlsx"
                onChange={handleFilesChangeNecessary}
              />

            </div>
            </div>

            <div className="files others">
                <div className="header">Остальные файлы</div>
                {files && files.map((file)=>(
                  <>{!file.necessary?
                  <>{file.error?<div className="error" key={file.id}>Ошибка загрузки файла {file.name}</div>:null}
                    {file.loading?<div className="loading" key={file.id}><img src={small_loader_svg}/>Загружается {file.name}</div>:null}
                    {!file.loading&&!file.error?<div file_id={file.id} key={file.id}><div className="delete" onClick={handleDelete}><img src={delete_svg}/></div><Link onClick={handleDownload}>{file.name}</Link></div>:null}
                    
                  
                  </>:null} </>

                 
                  ))}
                <div className="upload_files">
              <div className="button" onClick={handleAddFiles}>Добавить файлы</div>
              <input
                type="file"
                multiple={true}
                
                accept=".jpg,.jpeg,.png,.pdf,.doc,.xls,.xlsx"
                onChange={handleFilesChange}
              />

            </div>
            </div>

            
            
   
            {isError.length?<div className="error">{isError.map((error)=>(<div>{error}</div>))}</div>:null}
            {isSuccess.length?<div className="success">{isSuccess.map((success)=>(<div>{success}</div>))}</div>:null}
            {isLoading?<div className="loader"><div className="loader_row"><div></div><div></div><div></div><div></div></div></div>:<div className="button" onClick={handleSend}>Утвердить и отправить на формирование КС-2</div>}
            

          </div>
          :null}
        </div>
        </>
        :
        null
        }
        <div className="block">
          <div className="block_header">Список отчётов о выполнении</div>

          <div className="table">
              <div className="table_header">
                <div className="table_cell">
                  Номер
                </div>
                
                <div className="table_cell">
                  Период
                </div>
                <div className="table_cell">
                  Количество работ
                </div>
                <div className="table_cell">
                  Статус
                </div>
              </div>
              {isLoading? 
                <>
                <div className="table_row"><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div></div>
                <div className="table_row"><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div></div>
                <div className="table_row"><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div></div>
                 </>
              :
                <>
                {estimate.reports && estimate.reports.length?
                <>
                {estimate.reports.map((report) => (
      
                <div className="table_row" key={report.id}>
                    <div className="table_cell">
                        <Link to={`/report/${report.id}/`}>
                          <div className="link">{report.id}</div>
                          <div className="small_info">{new Date(report.date).toLocaleDateString()}</div>
                        </Link>
                      </div>
                     
                      <div className="table_cell">
                        {new Date(report.date_from).toLocaleDateString()} - {new Date(report.date_to).toLocaleDateString()}
                      </div>
                      <div className="table_cell">
                        {Object.keys(report.works).length}
                      </div>
                      <div className="table_cell">
                        {report.accept?<div className="accept">Принят</div>:null}
                        {report.reject?<div className="reject">Отклонён</div>:null}
                        {!report.accept&&!report.reject?<div className="wait">Отправлен на рассмотрение</div>:null}
                      </div>
                  </div>

              ))}
                </>
                  :
                  <div className="table_row"><div className="table_cell">Отчёты отсутствуют</div></div>
                }
                </>
              }
           </div>
          </div>
      </div>

    </div>
    </>
  );

}

