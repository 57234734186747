import React, { useEffect, useState } from 'react';
import axiosInstance from '../../components/AxiosInstance';
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';
import {config} from '../../config/config.js';
import { useAuth } from "../../components/AuthProvider";
import Menu from "../../components/Menu";
import TopBar from "../../components/TopBar";
import header_progress_svg from '../../assets/img/header_progress.svg';
import filter_svg from '../../assets/img/filter.svg';
import arrow_down_svg from '../../assets/img/arrow_down.svg';




export default function ActPage() {
  const { id } = useParams()
  const navigate = useNavigate();
  const [tableRow, setTableRow] = useState(0);
   const [act, setAct] = useState([]);
   const [isLoading, setisLoading]=useState(true);
   const [isError, setisError] = useState(false);
/*
  useEffect(() => {
    setisLoading(true);
// console.log('token', axios.defaults.headers.common["Authorization"])
   axiosInstance.get("/base/estimates/")
    .then(function(response){

      setEstimates(response.data)
      setisLoading(false)
    })
    .catch((err) => {
        setisError(true);
        setisLoading(false);
      });
  }, [])*/

  useEffect(() => {
    setisLoading(true);

    
     axiosInstance.get(`/base/act/${id}`)
    .then(function(response){
      

      console.log(response.data)

      setAct(response.data)
      setTableRow(2+Object.keys(response.data['total']).length*5)
      setisLoading(false)
    })
    .catch((err) => {
      setisError(true);
      setisLoading(false);
      });
    }, [])



  return (  
    <>
    <Menu/>
    <div id="page">
    <TopBar/>
    <div className="container">

      {isError?<div className="header">Акт не найден</div>:null}
      {act.number?
        <>
          <div className="header">Акт {act.number} от {act.date}</div>

          <div className="card_row">
            <div className="card">
                <div className="card_header">{act.number}</div>
                <div className="card_date">от {act.date}</div>
            </div>
            <div className="card pt">
              <div className="card_table">
                <div className="card_attr">Договор</div>
                <div className="card_value"><Link to={`/deal/${act.deal_id}/`}>{act.deal_name}</Link></div>
              </div>
              <div className="card_table">
                <div className="card_attr">Проект</div>
                <div className="card_value"><Link>{act.project_name}</Link></div>
              </div>
              <div className="card_table">
                <div className="card_attr">Объект</div>
                <div className="card_value"><Link>{act.object_name}</Link></div>
              </div>
            </div>
            <div className="card">
                <div className="card_header right">ИТОГО</div>
                {act && act.total && Object.keys(act.total).length && Object.keys(act.total).map((currency, index) => ( 
                  <div className="card_money">{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(act.total[currency])} {config.currency_code[currency].code}</div>
                ))}
                
            </div>
          </div>


  
          <div className="block">
           <div className="block_table">

            <table className="list" id="table">
            <thead>
              <tr>
                 
                <th>Наименование работ</th>
                <th>ID</th>
                <th>Кол-во</th>
                <th>Ед.изм.</th>
                  
                  {act.total && Object.keys(act.total).map((currency) => (  
                    <>
                    <th>Цена материала за ед., {currency}, без НДС</th>
                    <th>Стоимость материала, {currency}, без НДС</th>
                    <th>Цена работ за ед., {currency}, без НДС</th>
                    <th>Стоимость работ, {currency}, без НДС</th>
                    <th>Стоимость Итого, {currency}, без НДС</th>
                    </>
                  ))}
              </tr>

              
               </thead>       
               <tbody>

                {act.works && act.works.map((work) => (  
                  <>
                    
                     {work.section ?
              <tr className="section show" level={work.level} key={work.number}>
                <td>{work.name}</td>
                <td>{work.number}</td>
                <td className="right"></td>
                <td className="right"></td>
                {act.total && Object.keys(act.total).map((currency) => (
                <>
                <td className="right"></td>
                <td className="right"></td>
                <td className="right"></td>
                <td className="right"></td>
                <td className="right"></td>
                </>
              ))}
                


               
                
          
              </tr>
             :
              <tr className="show"  work_id={work} level={work.level} key={work.number}>
                <td>{work.name}</td>
                <td><span>{work.number}</span></td>
                
               
                <td className="center">{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(work.workload)}</td>
                <td className="center">{work.unit}</td>
                  {act.total && Object.keys(act.total).map((currency) => (
            <>
            
              {work.money[currency] ?
                <>  
                  {work.money[currency].cost_materials_per_unit ?
                   <td className="right">{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(work.money[currency].cost_materials_per_unit)}</td>
                  : 
                   <td className="right"></td>}

                   {work.money[currency].cost_materials ?
                   <td className="right">{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(work.money[currency].cost_materials)}</td>
                  : 
                   <td className="right"></td>}

                   {work.money[currency].cost_installation_per_unit ?
                   <td className="right">{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(work.money[currency].cost_installation_per_unit)}</td>
                  : 
                   <td className="right"></td>}

                   {work.money[currency].cost_installation ?
                   <td className="right">{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(work.money[currency].cost_installation)}</td>
                  : 
                   <td className="right"></td>}

                  {work.money[currency].cost_total ?
                   <td className="right">{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(work.money[currency].cost_total)}</td>
                  : 
                   <td className="right"></td>}
                  
                </>
              :
                <><td className="right"></td>
                  <td className="right"></td>
                  <td className="right"></td>
                  <td className="right"></td>
                  <td className="right"></td></>
              
              
              
              }
                

                    
                  </>
                ))}
              </tr>
              }

              
              </>
              ))}
                
                {act.indirects && act.indirects.length?
                <>
                <tr id="indirect" className="section show" level="1">
                    <td><b>Косвенные затраты</b></td>
                    <td>Уровень</td>
                    <td colSpan="2">Способ начисления</td>
                    
                    {Object.keys(act.money).map((currency) => ( 
                        <><td className="right" colSpan="4">Процент, {currency}</td>
                        <td className="right">Сумма, {currency}</td></>
                    ))}
                  

                </tr>
                {Object.keys(act.indirects).map((indirect) => ( 
                        <tr id="indirect" className="show" >
                            <td>{act.indirects[indirect].name}</td>
                            <td>{act.indirects[indirect].level}</td>
                            <td colSpan="2">{act.indirects[indirect].accrual}</td>
                           
                            {Object.keys(act.money).map((currency) => ( 

                                <>
                                {act.indirects[indirect].moneys[currency]?
                                <><td className="right" colSpan="4">{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(act.indirects[indirect].moneys[currency].percent.toFixed(2))}</td>
                                <td className="right">{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(act.indirects[indirect].moneys[currency].cost_total.toFixed(2))}</td>
                                </>:<><td colSpan="4"></td><td></td></>
                            }
                            </>
                            ))}
                            
                        </tr>
                    ))}
                </>
                :null}

                <tr className="show" >
                  <td className="right" colSpan="4"><b>Всего по смете,  без НДС:</b></td>
                  
                  {Object.keys(act.money).map((currency) => ( 
                    <>              
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="right"><b>{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(act.money[currency].cost_total)}</b></td>
                    </>
                  ))}
                </tr>
                {act.tax_value!='Без НДС'?
                <tr className="show" >
                  <td className="right" colSpan="4"><b>НДС {act.tax_value}:</b></td>
                  
                  {Object.keys(act.money).map((currency) => ( 
                    <>              
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="right"><b>{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format((act.money[currency].cost_total*parseInt(act.tax_value)/100).toFixed(2))}</b></td>
                    </>
                  ))}
                </tr>
                :null}
                {act.tax_value!='Без НДС'?
                <tr className="show" >
                  <td className="right" colSpan="4"><b>Всего по смете,  с НДС {act.tax_value}:</b></td>
                  
                  {Object.keys(act.money).map((currency) => ( 
                    <>              
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="right"><b>{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format((act.money[currency].cost_total*(parseInt(act.tax_value)+100)/100).toFixed(2))}</b></td>
                    </>
                  ))}
                </tr>
                :null}
                </tbody>
             
            </table>


          </div>
        </div>

        </>
        :
        null
        }
      </div>

    </div>
    </>
  );

}

